import { createSlice } from '@reduxjs/toolkit'


export const difalSaida = createSlice({
  name: "difalSaidaStates",
  initialState: {
    upload: true,
    meta: []
  },
  reducers: {
    handleUploadUpdate: (state) => {
      state.upload = !state.upload
    },
    handleMetaPaginate: (state, action) => {
      state.meta = action.payload
    }
  }
})


export const { handleUploadUpdate, handleMetaPaginate} = difalSaida.actions

export default difalSaida.reducer