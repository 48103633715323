// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import manutencao from './manutencao'
import difalSaida from '@src/views/Venda/DifalSaida/Store'
const rootReducer = {
  auth,
  navbar,
  layout,
  manutencao,
  difalSaida
}

export default rootReducer
