import { createContext, useEffect, useState } from "react"
import { formatCnpjCpf } from "../utility/formatCnpj"

import * as Sentry from "@sentry/react"
export const EmpresasContext = createContext({})

const item = localStorage.getItem('empresaSelecionada')
export function EmpresasProvider(props) {
  const initialUser = () => {
    const item = window.localStorage.getItem('userData')
    return item ? JSON.parse(item) : null
  }
  let empresa = false
  const user = initialUser()?.user

  if (user && user.clientes.length !== 0) {
    const primeiroCliente = user.clientes[0]
    const cnpjCpf = primeiroCliente.cnpj || primeiroCliente.cpf
    const nome = primeiroCliente.nome
    const estado = primeiroCliente.estado

    empresa = {
      name: `${formatCnpjCpf(cnpjCpf)}, ${nome}, ${estado}`,
      cep: primeiroCliente.cep,
      id: user.clientes[0]?.id
    }
  }
  const empresaInitial = item === null ? empresa : JSON.parse(item)
  const [currentEmpresa, setCurrentEmpresa] = useState(empresaInitial)
  const estado = currentEmpresa === false ? false : currentEmpresa.name.split(",").pop().trim().toLowerCase()
  const cnpjWithoutMask = currentEmpresa === false ? false : currentEmpresa.name.split(",")[0].replace(/[^\d]+/g, "")
  const cep = !currentEmpresa ? '' : currentEmpresa.cep
  const [idEmpresa, setEmpresa] = useState('')
  const [sideEffect, SetSideEffect] = useState(false)
  function handleEmpresa(value) {
    setCurrentEmpresa(value)
    localStorage.setItem('empresaSelecionada', JSON.stringify(value))
  }

  function handleId(value) {
    setEmpresa(value)
  }

  function handleEffect() {
    SetSideEffect(!sideEffect)
  }

  useEffect(() => {
    if (initialUser()) {
      Sentry.setUser({ email: initialUser()?.autenticacao?.email })
    }
  }, [initialUser()])
  return (
    <EmpresasContext.Provider value={{ currentEmpresa, handleEmpresa, handleId, idEmpresa, cnpjWithoutMask, estado, handleEffect, sideEffect, cep }} >
      {props.children}
    </EmpresasContext.Provider>
  )
}