import { createContext, useState } from "react"

export const CestContext = createContext({})

export function CestProvider(props) {
  const [estadosCest, setEstadosCest] = useState({
    "AC": "",
    "AL": "",
    "AP": "",
    "AM": "",
    "BA": "",
    "CE": "",
    "DF": "",
    "ES": "",
    "GO": "",
    "MA": "",
    "MT": "",
    "MS": "",
    "MG": "",
    "PA": "",
    "PB": "",
    "PR": "",
    "PE": "",
    "PI": "",
    "RJ": "",
    "RN": "",
    "RS": "",
    "RO": "",
    "RR": "",
    "SC": "",
    "SP": "",
    "SE": "",
    "TO": ""
  })
  const [cestData, setCestData] = useState()
  const [cestState, setCestState] = useState()
  const [currentCest, setCurrentCest] = useState({
    id: 5212,
    cest: "Escolha o CEST",
    aliquota_interna: null,
    descricao: null,
    regra: null,
    subregra: null,
    segmento: null
  })
  function handleCestState(value) {
    setCestState(value)
  }

  function handleCurrentCest(value) {
    setCurrentCest(value)
  }

  function handleCestData(value) {
    setCestData(value)
  }

  function handleCestFiltered(value) {
    setEstadosCest(value)
  }
  const handleCestChangeState = (estado, cest_id = '', ncm_id = '', cest) => {
    setEstadosCest({
      ...estadosCest,
      [estado]: {
        cest_id,
        ncm_id,
        cest
      }
    })
  }
  const handleReset = () => {
    setEstadosCest({
      "AC": "",
      "AL": "",
      "AP": "",
      "AM": "",
      "BA": "",
      "CE": "",
      "DF": "",
      "ES": "",
      "GO": "",
      "MA": "",
      "MT": "",
      "MS": "",
      "MG": "",
      "PA": "",
      "PB": "",
      "PR": "",
      "PE": "",
      "PI": "",
      "RJ": "",
      "RN": "",
      "RS": "",
      "RO": "",
      "RR": "",
      "SC": "",
      "SP": "",
      "SE": "",
      "TO": ""
    })
  }

  return (
    <CestContext.Provider value={{ cestState, currentCest, handleReset, handleCestState, handleCurrentCest, cestData, handleCestData, setCurrentCest, handleCestChangeState, estadosCest, handleCestFiltered }}>
      {props.children}
    </CestContext.Provider>
  )
} 