import { createContext, useState } from "react"

export const GuiasContext = createContext({})


export function GuiasContextProvider(props) {
  const [guiasState, setGuiaState] = useState(false)
  const [guiasId, setGuiasId] = useState('')
  const [guiasSaidaId, setGuiasSaidaId] = useState('')

  function handleWithGuiasState() {
    setGuiaState(!guiasState)
  }

  return (
    <GuiasContext.Provider value={{ guiasState, handleWithGuiasState, guiasId, setGuiasId, setGuiasSaidaId, guiasSaidaId }}>
      {props.children}
    </GuiasContext.Provider>
  )
}